<template>
  <div class="ad-contact">
    <moe-header
      :bg="backgroundImage"
      :mobileBg="backgroundImage"
      height="470px"
      mobileHeight="320px"
    >
      <div class="banner-text-container">
        <div class="en-title">
          <img :src="textImage" />
        </div>
        <h1 class="cn-title">联系我们</h1>
      </div>
    </moe-header>

    <moe-navigation-bar />

    <div class="content-container">
      <div class="title">
        <div class="tit">
          <h2>{{ company }}</h2>
          <p>{{ slogan }}</p>
        </div>
        <div class="pic"></div>
      </div>
      <div class="content">
        <div class="address">
          <!--<p class="en-address">-->
          <!--  7th Floor,Block B,Kingdee Software Park,Keji Nan 12th Road,Nanshan-->
          <!--  District, Shenzhen City,Guangdong Province,China-->
          <!--</p>-->
          <div class="kf">
            <p>{{ `${location.province}，${location.city}，${location.area}` }}</p>
            <p>{{ `${location.street}，${location.address}` }}</p>
          </div>
          <div class="lx">
            <p v-if="contact.telephone">座机：{{ contact.telephone }}</p>
            <p v-if="contact.mobilephone">手机：{{ contact.mobilephone }}</p>
            <p v-if="contact.email">邮箱：{{ contact.email }}</p>
          </div>
        </div>
        <div class="map">
          <img :src="mapImage" style="width: 300px; height: 420px; object-fit: cover; border-radius: 12px" />
        </div>
      </div>
    </div>

    <moe-footer></moe-footer>
  </div>
</template>

<script>
import { IMAGES } from '@/settings'
import enterpriseApi from "@/api/enterprise"
import { mapGetters } from "vuex"
import { actions, getters } from "@/store/helper"
import store from "@/store"

export default {
  name: 'Contact',
  data() {
    return {
      textImage: null,
      backgroundImage: null,
      mapImage: null,
      company: null,
      slogan: null,
      contact: {},
      location: {},
    }
  },
  computed: {
    ...mapGetters({
      developerMap: getters.site.developer,
      contactMap: getters.site.contact,
    }),
  },
  watch: {
    developerMap(newVal) {
      this.company = newVal.companyCN
      this.slogan = newVal.companyEN
    },
    contactMap(newVal) {
      this.textImage = newVal.contactTI
      this.backgroundImage = newVal.contactBG
      this.mapImage = newVal.mapIMG
    }
  },
  created() {
    store.dispatch(actions.site.fetchSite)
    store.dispatch(actions.site.fetchEnterprise)
    this.getContact()
  },
  methods: {
    async getContact() {
      let { data } = await enterpriseApi.readContact()
      console.log("联系我们：", data)
      this.location = data.location
      this.contact = data.contact
    },
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .ad-contact {
    .banner-text-container {
      display: none !important;
    }

    .content-container {
      max-width: 100% !important;
      box-sizing: border-box;
      margin: -50px auto auto auto !important;
      background: #ffffff;
      border-radius: 20px;
      position: relative;
      z-index: 10;
      padding: 20px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      .title {
        width: 100% !important;
        height: auto !important;
        padding-top: 30px !important;

        .tit {
          flex: 1;
          h2 {
            font-size: 22px !important;
          }
          p {
            font-size: 12px !important;
            color: #222 !important;
          }
        }
      }
    }
    .content {
      width: 100% !important;
      padding-bottom: 30px !important;
      .address {
        flex: 1;
        .en-address {
          width: 100% !important;
          font-size: 9px !important;
          word-wrap: break-word;
        }

        .kf {
          margin-top: 28px !important;
          p {
            font-size: 12px !important;
          }
        }
        .lx {
          margin-top: 28px !important;
          p {
            font-size: 12px !important;
          }
        }
      }
      .map {
        display: none !important;
      }
    }
    .footer-container {
      margin-top: 0px !important;
    }
  }
}
.ad-contact {
  width: 100%;
  background: #f6f9fa;

  .banner-text-container {
    max-width: 1180px;
    height: 100%;
    text-align: center;
    padding-top: 175px;
    h1 {
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      margin-top: 18px;
    }
  }

  .content-container {
    max-width: 1180px;
    box-sizing: border-box;
    margin: -170px auto auto auto;
    background: #ffffff;
    border-radius: 20px;
    position: relative;
    z-index: 10;
    .title {
      width: 948px;
      height: 170px;
      box-sizing: border-box;
      margin: 0px auto auto auto;
      display: flex;
      align-items: flex-start;
      padding-top: 80px;

      .tit {
        flex: 1;
        h2 {
          font-size: 30px;
          font-weight: 600;
          color: #222222;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          letter-spacing: 2px;
          margin-top: 20px;
        }
      }
      .pic {
      }
    }
  }
  .content {
    width: 948px;
    margin: 4px auto;
    padding-bottom: 100px;
    display: flex;
    align-items: flex-start;
    .address {
      flex: 1;
      .en-address {
        width: 521px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }

      .kf {
        margin-top: 58px;
        p {
          font-size: 16px;
          font-weight: 400;
          color: #222222;
          line-height: 32px;
        }
      }
      .lx {
        margin-top: 50px;
        p {
          font-size: 16px;
          font-weight: 400;
          color: #222222;
          line-height: 32px;
        }
      }
    }
    .map {
      img {
        margin-top: -82px;
      }
    }
  }
  .footer-container {
    margin-top: 100px;
  }
}
</style>
